import React from "react";
import Env from "../../utils/environments";
import logo_footer from "../../assets/images/logo-footer.png";
import qr_code from "../../assets/images/qr-code.png";
import app_store from "../../assets/images/appstore.png";
import googleplay from "../../assets/images/googleplay.png";
import ic_fb from "../../assets/images/ic-fb.png";
import ic_tw from "../../assets/images/ic-tw.png";
import ic_4 from "../../assets/images/ic-4.png";
import ic_medium from "../../assets/images/ic-medium.png";

const Footer = () => {
  const BASE_TOMOCHAIN_URL = `${Env.SCAN_CHAIN_NETWORK}/address/`;

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__logo">
            <a href={Env.BASE_URL}>
              <img src={logo_footer} alt="" />
            </a>
            <p className="copyright">
              © 2021 Triip Pte. Ltd.
              <br />
              All rights reserved.
            </p>
          </div>
          <div className="footer__list">
            <div className="footer__item">
              <h3 className="footer__title style">About Triip.me</h3>
              <ul className="footer__menu">
                <div className="footer__link">
                  <a href={`${Env.BASE_URL}/pages/about_us`}>About Us</a>
                </div>
                <div className="footer__link">
                  <a href={`${Env.BASE_URL}/pages/terms_of_service`}>
                    Policies
                  </a>
                </div>
                <div className="footer__link">
                  <a href={`${Env.BASE_URL}/pages/contact_us`}>Contact</a>
                </div>
                <div className="footer__link">
                  <a href={Env.TRIIP_SHARE_URL}>Blog</a>
                </div>
                <div className="footer__link">
                  <a href={`${Env.BASE_URL}/pages/faqs`}>FAQs</a>
                </div>
              </ul>
            </div>
            <div className="footer__item">
              <h3 className="footer__title style">Products</h3>
              <ul className="footer__menu text-right">
                <div className="footer__link">
                  <a href={Env.TRIIP_STAY_URL}>Triip Stays</a>
                </div>
                <div className="footer__link">
                  <a href={Env.TRIIP_EXPERIENCE_URL}>Triip Experiences</a>
                </div>
                <div className="footer__link">
                  <a href="https://triipmiles.com/">TriipMiles</a>
                </div>
                <div className="footer__link">
                  <a href={Env.TRIIP_SHOPPING_URL}>Triip Shopping</a>
                </div>
              </ul>
            </div>
          </div>
          <div className="footer__item items-center">
            <h3 className="footer__title">Download Triip App</h3>
            <div className="footer__info">
              <div className="footer__qr">
                <img src={qr_code} alt="" />
              </div>
              <div className="footer__or">
                <span>or</span>
              </div>
              <div className="footer__download">
                <a href="https://link.triip.me/paJG79NjP3">
                  <img src={app_store} alt="" />
                </a>
                <a href="https://link.triip.me/X4h9PHpjP3">
                  <img src={googleplay} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="follow">
          <h3 className="follow__title">Follow us on</h3>
          <ul className="follow__list">
            <li className="follow__item">
              <a href="https://www.facebook.com/triip.me/">
                <img src={ic_fb} alt="" />
              </a>
            </li>
            <li className="follow__item">
              <a href="https://twitter.com/triipme">
                <img src={ic_tw} alt="" />
              </a>
            </li>
            <li className="follow__item">
              <a href="https://t.me/triipmilesICO">
                <img src={ic_4} alt="" />
              </a>
            </li>
            <li className="follow__item">
              <a href="https://medium.com/triipmiles">
                <img src={ic_medium} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
