/* eslint-disable dot-notation */
import axios from "axios";
import Env from "./environments";

let isRefreshing = false;
let failedQueue = [];

const defaultContentType = "application/json";

const requestConfig = {
  url: "/",
  method: "GET",
  baseURL: Env.BASE_URL,
  headers: {
    common: {
      "Content-Type": defaultContentType,
      "Accept-Language": "vi",
    },
  },
  params: {},
  data: JSON.stringify({}),
  timeout: 120000,
  withCredentials: false,
};

export function get({ url, params = {}, headersAuthen, userCode }) {
  requestConfig.url = url;
  requestConfig.method = "GET";
  requestConfig.params = params;
  if (headersAuthen) {
    requestConfig.headers.common["Authorization"] = `${headersAuthen}`;
    requestConfig.headers.common["User-Code"] = `${userCode}`;
  }

  return axios(requestConfig);
}

export function post({
  url,
  params = {},
  data = {},
  headersAuthen,
  userCode,
  onUploadProgress = null,
}) {
  requestConfig.url = url;
  requestConfig.method = "POST";
  requestConfig.params = params;
  requestConfig.data = data;

  if (onUploadProgress !== null) {
    requestConfig.onUploadProgress = onUploadProgress;
  }

  if (headersAuthen) {
    requestConfig.headers.common["Authorization"] = `${headersAuthen}`;
    requestConfig.headers.common["User-Code"] = `${userCode}`;
  }

  return axios(requestConfig);
}

export function put({ url, params = {}, data = {}, needToken = false }) {
  requestConfig.url = url;
  requestConfig.method = "PUT";
  requestConfig.params = params;
  requestConfig.data = data;

  const headersAuthen = "";
  // request header
  if (needToken) {
    requestConfig.headers.common["Authorization"] = `Bearer ${headersAuthen}`;
  }

  return axios(requestConfig);
}

export function del({ url, params = {}, data = {}, headersAuthen = null }) {
  requestConfig.url = url;
  requestConfig.method = "DELETE";
  requestConfig.params = params;
  requestConfig.data = data;

  // request header
  if (headersAuthen !== null) {
    requestConfig.headers.common["x-csrf-token"] = headersAuthen;
  }

  return axios(requestConfig);
}

export function download({
  url,
  params = {},
  data = {},
  headersAuthen = null,
}) {
  requestConfig.url = url;
  requestConfig.method = "POST";
  requestConfig.params = params;
  requestConfig.data = data;
  // request header
  if (headersAuthen !== null) {
    requestConfig.headers.responseType = "blob";
    requestConfig.headers.common["Authorization"] = `Bearer ${headersAuthen}`;
  }

  return axios(requestConfig);
}
