import Env from "./environments";

function getDomain(url, subdomain) {
  subdomain = subdomain || false;
  url = url.replace(/(https?:\/\/)?(www.)?/i, '');
  if (!subdomain) {
    url = url.split('.');
    url = url.slice(url.length - 2).join('.');
  }
  if (url.indexOf('/') !== -1) {
    return url.split('/')[0];
  }
  return url;
}

export function createAuthCookie(name, value, days) {
  const key = name + Env.NODE_ENV;
  const authCookie = key + "=" + escape(value) + ";";
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${key}=${escape(value)}; domain=.${getDomain(document.domain)}; expires=${d.toUTCString()}; path=/;`
}

export function getAuthCookie(name) {
  name = `${name}${Env.NODE_ENV}=`;
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}