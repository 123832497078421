import React from "react";
import { ChevronBackOutline, ChevronForwardOutline } from "react-ionicons";

import partners_01 from '../assets/images/partners-01.png';
import partners_02 from '../assets/images/partners-02.png';
import partners_03 from '../assets/images/partners-03.png';
import partners_04 from '../assets/images/partners-04.png';
import partners_05 from '../assets/images/partners-05.png';
import partners_06 from '../assets/images/partners-06.png';
import partners_07 from '../assets/images/partners-07.png';
import partners_08 from '../assets/images/partners-08.png';
import attraction_icon from '../assets/images/attraction-icon.png';
import tours_icon from '../assets/images/tours-icon.png';
import lifestyle_icon from '../assets/images/Lifestyle-icon.png';

export const slideSettings = {
  className: "section-outstanding__slider",
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  infinite: false,
  nextArrow: (
    <ChevronForwardOutline color={"#fff"} width="40px" height="40px" />
  ),
  prevArrow: <ChevronBackOutline color={"#fff"} width="40px" height="40px" />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const sliderItem = [
  {
    id: 1,
    note: "Get 8 TIIM for first login",
    // text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "https://via.placeholder.com/99",
  },
  {
    id: 2,
    note: "Get 11 TIIM for refer a friend successfully",
    // text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "https://via.placeholder.com/99",
  },
  {
    id: 3,
    note: "Get 3 TIIM for completing account verification",
    // text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "https://via.placeholder.com/99",
  },
  {
    id: 4,
    note: "Get 264 TIIM for a travel proof approved",
    // text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "https://via.placeholder.com/99",
  },
];

export const listPartner = [
  {
    id: 1,
    url: partners_01,
  },
  {
    id: 2,
    url: partners_02,
  },
  {
    id: 3,
    url: partners_03,
  },
  {
    id: 4,
    url: partners_04,
  },
  {
    id: 5,
    url: partners_05,
  },
  {
    id: 6,
    url: partners_06,
  },
  {
    id: 7,
    url: partners_07,
  },
  {
    id: 8,
    url: partners_08,
  },
];

export const TIMELINE = [
  {
    id: 1,
    text: "Launch the Triip app and tap on “Hotel & Homes”",
  },
  {
    id: 2,
    text: "Choose a booking partner",
  },
  {
    id: 3,
    text: "Search and select a hotel",
  },
  {
    id: 4,
    text: "Book and complete payment",
  },
];

export const CATEGORIES = [
  {
    name: "Attractions",
    image: attraction_icon,
  },
  {
    name: "Tours",
    image: tours_icon,
  },
  {
    name: "Lifestyle",
    image: lifestyle_icon,
  },
];
