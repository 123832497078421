import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/stores";
import Header from "./components/Header";
import "./index.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import "antd/dist/antd.css";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import phone from "./assets/images/holding-phone-mockup.png";
import qr_code from "./assets/images/qr-code.png";
import app_store from "./assets/images/appstore.png";
import googleplay from "./assets/images/googleplay.png";

const Download = () => {
  return (
    <Provider store={store}>
      <Header />
      <div className="flex items-center justify-center download">
        <div className="footer__item items-center">
          <div className="flex flex-col md:flex-row items-center">
            <img className="md:w-1/2 w-full" src={phone} />
            <div className="md:w-1/2 w-full mt-4 md:mt-0 flex flex-col justify-center items-center">
              <h3 className="uppercase mb-4 text-white font-bold">
                Download Triip App
              </h3>
              <div className="footer__info">
                <div className="footer__qr">
                  <img src={qr_code} alt="" />
                </div>
                <div className="footer__or">
                  <div className=" bg-white w-8 flex items-center justify-center h-8 rounded-full">
                    or
                  </div>
                </div>
                <div className="footer__download">
                  <a href="https://link.triip.me/paJG79NjP3">
                    <img src={app_store} alt="" />
                  </a>
                  <a href="https://link.triip.me/X4h9PHpjP3">
                    <img src={googleplay} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default Download;
