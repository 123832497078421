import React, { useState, useEffect } from "react";
import { Modal, DropdownButton, Dropdown } from "react-bootstrap";
import Login from "../Login";
import Register from "../Register";

const LoginRegister = ({ openLogin, setOpenLogin }) => {
  const [type, setType] = useState(1);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const t = (value) => value;

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Congratulation!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have been successfully registered. To active your account check
          your email <span className="font-bold">{email}</span> and confirm your
          registration. If you don’t get this email, please wait for 2-5
          minutes. Also, check your spam folder and follow the instruction.
        </Modal.Body>
        <Modal.Footer>
          <button className="button_primary" onClick={() => setShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={openLogin} onHide={() => setOpenLogin(false)}>
        <Modal.Body>
          <div className="flex">
            <div
              className={`${
                type === 1 ? "button__tab_active" : ""
              } button__tab cursor-pointer p-4 border-solid border-gray-500 justify-center font-bold flex uppercase flex-1`}
              onClick={() => setType(1)}
            >
              Login
            </div>
            <div
              className={`${
                type === 2 ? "button__tab_active" : ""
              } button__tab cursor-pointer border-gray-500 p-4 justify-center font-bold flex uppercase flex-1`}
              onClick={() => setType(2)}
            >
              Register
            </div>
          </div>
          {type == 1 && <Login onClose={() => setOpenLogin(false)} />}
          {type == 2 && (
            <Register
              setShow={setShow}
              setEmail={setEmail}
              onClose={() => setOpenLogin(false)}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginRegister;
