import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { submitLogin } from "../../redux/actions/authen";
import { useDispatch } from "react-redux";

const Login = ({ onClose }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [infor, setInfor] = useState({
    email: "",
    password: "",
  });

  const onChangeInfor = (value, name) => {
    const newInfor = Object.assign({}, infor);
    newInfor[name] = value;
    setInfor(newInfor);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(submitLogin(infor, onSuccess, onError));
  };

  const onSuccess = () => {
    onClose();
  };

  const onError = () => {
    setError(true);
  };

  return (
    <form onSubmit={handleSubmit} className="login">
      <div className="field-row mb-3">
        <div className="field">
          <input
            placeholder="E-mail"
            type="email"
            className="field__input"
            id="email"
            onChange={(e) => onChangeInfor(e.target.value, "email")}
          />
        </div>
      </div>
      <div className="field-row mb-3">
        <div className="field">
          <input
            placeholder="Password"
            type="password"
            className="field__input"
            id="password"
            onChange={(e) => onChangeInfor(e.target.value, "password")}
          />
        </div>
      </div>
      {error && (
        <div className="text-red-500 mb-3">Invalid Email or Password.</div>
      )}
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Remember me" />
      </Form.Group>
      <button type="submit" className="login__submit mb-3 mt-3">
        Sign in
      </button>
      <a href="/?mod=forgot_password" className="login__forgot text-center block">Forgot your password?</a>
    </form>
  );
};

export default Login;
