import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Slider from "react-slick";
import { submitRegister } from "./redux/actions/authen";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useRef } from "react";
import { listPartner, sliderItem, slideSettings } from "./utils/constant";
import { useContainerDimensions } from "./utils/customHook";
import Reaptcha from "reaptcha";
import YouTube from "react-youtube";
import LoginRegister from "./components/LoginRegister";
import Env from "./utils/environments";
import styles from "./styles/Pages/home.module.scss";
import service_1 from "./assets/images/services-1.png";
import service_2 from "./assets/images/services-2.png";
import step_1 from "./assets/images/step-1.png";
import step_2 from "./assets/images/step-2.png";
import step_3 from "./assets/images/step-3.png";
import tab_1 from "./assets/images/tab-1.png";
import tab_2 from "./assets/images/tab-2.png";
import tab_3 from "./assets/images/tab-3.png";
import tiim_img from "./assets/images/TIIM-small.png";
import welcome from "./assets/images/Welcomeback.png";
import app_img from "./assets/images/app-img.png";
import home_guess from "./assets/images/home-guess.jpg";
import home_guess_mobile from "./assets/images/home-guess__mobile.jpg";
import home_login from "./assets/images/home.jpg";
import home_login_mobile from "./assets/images/home_login__mobile.png";

const Home = () => {
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const dispatch = useDispatch();
  const authen = useSelector((state) => state.authen);
  const ref = useRef(null);
  const [openLogin, setOpenLogin] = useState(false);
  const captcharef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const t = (value) => value;
  const [errorForm, seterrorForm] = useState({
    first_name: {
      required: false,
    },
    last_name: {
      required: false,
    },
    email: {
      required: false,
      format: false,
    },
    password: {
      required: false,
    },
    g_recaptcha_response: {
      required: false,
    },
  });

  const [inforRegister, setInforRegister] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    referral_code: "",
    g_recaptcha_response: "",
  });

  const [tabActive, setTabActive] = useState(1);
  const [show, setShow] = useState(false);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateField = () => {
    const { first_name, last_name, password, email, g_recaptcha_response } =
      inforRegister;
    const validateForm = {
      first_name: {
        required: first_name === "",
      },
      last_name: {
        required: last_name === "",
      },
      email: {
        required: email === "",
        format: email !== "" && !validateEmail(email),
      },
      password: {
        required: password === "",
      },
      g_recaptcha_response: {
        required: g_recaptcha_response === "",
      },
    };

    const check = Object.values(validateForm).filter((item) => {
      if (item.required || item.format) {
        return true;
      }
      return false;
    });
    const newvalidInfor = { ...errorForm, ...validateForm };
    seterrorForm(newvalidInfor);
    if (check.length !== 0) {
      return true;
    }
    return false;
  };

  const submit = (e) => {
    e.preventDefault();
    if (validateField()) {
      return false;
    }

    dispatch(submitRegister(inforRegister, onSuccess, onError));
  };

  const onError = (error) => {
    if (captcharef) {
      captcharef.current.reset();
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      setErrorMessage(error.response.data.message);
    }
  };

  const onSuccess = () => {
    setShow(true);
    if (captcharef) {
      captcharef.current.reset();
    }
  };

  const handleTab = (numberActive) => {
    switch (numberActive) {
      case 1:
      default:
        return (
          <div className="tab-content__inner">
            <div className="tab-content__description">
              <ul className="tab-content__list">
                <li className="tab-content__option">
                  More than 28 million reported accommodation listings,
                  including over 6.2 million homes, apartments, and other unique
                  places to stay.
                </li>
                <li className="tab-content__option">
                  Better than the best price
                </li>
                <li className="tab-content__option">
                  Large inventory from Booking.com and Agoda
                </li>
                <li className="tab-content__option">
                  Easily booking with TriipMiles
                </li>
              </ul>
              <div className="tab-content__partner">
                <div className="partner-item">
                  <img src={service_1} alt="" />
                </div>
                <div className="partner-item">
                  <img src={service_2} alt="" />
                </div>
              </div>
            </div>
            <div className="tab-content__preview">
              <div className="tab-content__avatar">
                <img src={tab_1} alt="" />
              </div>
              <div className="tab-content__button">
                <a
                  href={Env.TRIIP_STAY_URL}
                  target="_blank"
                  className="button button--big button--preview"
                >
                  Sign up now
                </a>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="tab-content__inner">
            <div className="tab-content__description">
              <ul className="tab-content__list">
                <li className="tab-content__option">
                  100,000+ activies in 100+ countries
                </li>
                <li className="tab-content__option">Best price guranateed</li>
                <li className="tab-content__option">
                  Quick payment, instant ticket receipt
                </li>
                <li className="tab-content__option">
                  Easily booking with TIIM
                </li>
              </ul>
            </div>
            <div className="tab-content__preview">
              <div className="tab-content__control">
                <div className="tab-content__label">Experiences</div>
                <div className="tab-content__label">Tickets</div>
              </div>
              <div className="tab-content__avatar">
                <img src={tab_2} alt="" />
              </div>
              <div className="tab-content__button">
                <a
                  href={Env.TRIIP_EXPERIENCE_URL}
                  target="_blank"
                  className="button button--big button--preview"
                >
                  Book now
                </a>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="tab-content__inner">
            <div className="tab-content__description">
              <ul className="tab-content__list">
                <li className="tab-content__option">
                  Personalized design, affordable prices
                </li>
                <li className="tab-content__option">Global shipping</li>
                <li className="tab-content__option">High quality materials</li>
                <li className="tab-content__option">
                  Easily booking with TIIM
                </li>
              </ul>
            </div>
            <div className="tab-content__preview">
              <div className="tab-content__avatar">
                <img src={tab_3} alt="" />
              </div>
              <div className="tab-content__button">
                <a
                  href={Env.TRIIP_SHOPPING_URL}
                  target="_blank"
                  className="button button--big button--preview"
                  rel="noreferrer"
                >
                  Shop now
                </a>
              </div>
            </div>
          </div>
        );
    }
  };

  const handleChangeInfor = (value, name) => {
    const newInfor = Object.assign({}, inforRegister);
    const newValidateForm = Object.assign({}, errorForm);
    newInfor[name] = value;
    newValidateForm[name].required = false;
    setInforRegister(newInfor);
    seterrorForm(newValidateForm);
    setErrorMessage("");
    ref.current = newInfor;
  };

  const handleVerify = (token) => {
    const newInfor = Object.assign({}, ref.current);
    newInfor["g_recaptcha_response"] = token;
    const newValidateForm = Object.assign({}, errorForm);
    newValidateForm["g_recaptcha_response"].required = false;
    seterrorForm(newValidateForm);
    setErrorMessage("");
    setInforRegister(newInfor);
  };

  const opts = {
    height: "450",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Congratulation!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have been successfully registered. To active your account check
          your email <span className="font-bold">{inforRegister.email}</span>{" "}
          and confirm your registration. If you don’t get this email, please
          wait for 2-5 minutes. Also, check your spam folder and follow the
          instruction.
        </Modal.Body>
        <Modal.Footer>
          <button className="button_primary" onClick={() => setShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <LoginRegister openLogin={openLogin} setOpenLogin={setOpenLogin} />
      <div ref={componentRef} className={styles.pageWrap}>
        <Header />
        <div className="page-body">
          <section
            className={`${!authen.isLogin && "banner_hidden"} banner`}
            style={{
              backgroundImage: `url(${
                width && width >= 768 ? home_login : home_login_mobile
              })`,
            }}
          ></section>
          <section
            className={`${authen.isLogin && "banner_hidden"} banner`}
            style={{
              backgroundImage: `url(${
                width && width >= 768 ? home_guess : home_guess_mobile
              })`,
            }}
          ></section>
          <section className="section-form">
            <div className="container">
              <div className="form">
                <div className="form__intro">
                  <div className="form__heading">
                    Own the world’s first sustainable travel co-op
                  </div>
                  <h5 className="form__note">
                    Become a co-owner today to earn money for future trips while
                    saving our home planet
                  </h5>
                </div>
                {width && width < 768 && (
                  <div className="video-mobile">
                    <YouTube videoId="78hvrYFh26w" opts={opts} />
                  </div>
                )}
                {authen.isLogin ? (
                  <div className="form__box_welcome">
                    <div className="title">WELCOME BACK 👋</div>
                    <div className="description">
                      Let’s start our journey! <br /> Choose your path below
                    </div>
                    <div className="group-link">
                      <a
                        className="link link-yellow"
                        href={`${Env.TRIIP_SHARE_URL}/c/xin-chao-thanh-vien-moi/`}
                      >
                        Share to <br /> earn $TIIM
                      </a>
                      <a
                        className="link link-private"
                        href={Env.TRIIP_STAY_URL}
                      >
                        Stay to <br /> earn $TIIM
                      </a>
                      <a
                        className="link link-private"
                        href={Env.TRIIP_EXPERIENCE_URL}
                      >
                        Experience to <br />
                        earn $TIIM
                      </a>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={submit} className="form__box">
                    <h3 className="form__title">Become a co-owner</h3>
                    <div className="field-row">
                      <div className="field">
                        <label htmlFor="first-name" className="field__label">
                          First name
                        </label>
                        <input
                          type="text"
                          className="field__input"
                          id="first-name"
                          onChange={(e) =>
                            handleChangeInfor(e.target.value, "first_name")
                          }
                        />
                        {errorForm.first_name.required && (
                          <span className="text-red-400">
                            Please fill out this field
                          </span>
                        )}
                      </div>
                      <div className="field">
                        <label htmlFor="last-name" className="field__label">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="field__input"
                          id="last-name"
                          onChange={(e) =>
                            handleChangeInfor(e.target.value, "last_name")
                          }
                        />
                        {errorForm.last_name.required && (
                          <span className="text-red-400">
                            Please fill out this field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="field-row">
                      <div className="field">
                        <label htmlFor="email" className="field__label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="field__input"
                          id="email"
                          placeholder="your email is also the ownership ID"
                          onChange={(e) =>
                            handleChangeInfor(e.target.value, "email")
                          }
                        />
                        {errorForm.email.required && (
                          <span className="text-red-400">
                            Please fill out this field
                          </span>
                        )}
                        {errorForm.email.format && (
                          <span className="text-red-400">
                            This email not valid
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="field-row">
                      <div className="field">
                        <label htmlFor="password" className="field__label">
                          Password
                        </label>
                        <input
                          type="password"
                          className="field__input field__input__center"
                          id="password"
                          onChange={(e) =>
                            handleChangeInfor(e.target.value, "password")
                          }
                        />
                        {errorForm.password.required && (
                          <span className="text-red-400">
                            Please fill out this field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="field-row field-row-nowrap mt-3 flex items-stretch  field-recaptcha">
                      <div className="pr-2 recaptcha">
                        <Reaptcha
                          ref={captcharef}
                          sitekey="6Ld6YdwUAAAAAO8KVIlGLVLPrLoQgLLMQTPnbb_X"
                          onVerify={handleVerify}
                        />
                        {errorForm.g_recaptcha_response.required && (
                          <span className="text-red-400">
                            Please prove that you're a traveler, not a bot.
                            Otherwise, we can not let you through.
                          </span>
                        )}
                        {errorMessage && (
                          <span className="text-red-400">{errorMessage}</span>
                        )}
                      </div>
                      <button
                        type="submit"
                        onClick={submit}
                        className="button button--primary"
                      >
                        Join Now
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </section>
          {width && width >= 768 && (
            <section className="video">
              <div className="container">
                <YouTube videoId="78hvrYFh26w" opts={opts} />
              </div>
            </section>
          )}
          <section className="partner">
            <div className="container">
              <h2 className="partner__title title">Our strategic partners</h2>
              <ul className="partner__list">
                {listPartner.map((item) => (
                  <li className="partner__item" key={item.id}>
                    <div className="partner__img">
                      <img src={item.url} alt="" />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section className="app-about">
            <div className="container">
              <div className="app-about__inner">
                <div className="app-about__title title">
                  The fastest-growing blockchain travel co-op
                </div>
                <div className="app-about__list">
                  <div className="app-about__info info-left pt-10">
                    <div className="about-info__item left">
                      <span>430,000+</span> trust users
                    </div>
                    <div className="about-info__item left">
                      <span>30,000+</span> travel plan submitted
                    </div>
                    <div className="about-info__item left">
                      <span>40,000,000+ </span> 80,000+ Tours & Activites
                    </div>
                  </div>
                  <div className="app-preview">
                    <img src={app_img} alt="" />
                  </div>
                  <div className="app-about__info info-right pt-10">
                    <div className="about-info__item right">
                      The only travel app based-on blockchain network rewards
                      users whatever they completed.
                    </div>
                    <div className="about-info__item right">
                      AI-powered KYC & Travel Plan solutions
                    </div>
                    <div className="about-info__item right">
                      Safe security payment by international standards.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="step">
            <div className="container">
              <div className="step__inner">
                <h2 className="step__title title">It’s easy to get started</h2>
                <ul className="step__list">
                  <li className="step__item">
                    <div className="step__avatar">
                      <img src={step_1} alt="" />
                    </div>
                    <h3 className="step__note">
                      1. Create your account
                      <span>
                        Signing up for your own Triip account is easy and free{" "}
                      </span>
                    </h3>
                  </li>
                  <li className="step__item">
                    <div className="step__avatar">
                      <img src={step_2} alt="" />
                    </div>
                    <h3 className="step__note">
                      2. Confirm your email address
                    </h3>
                  </li>
                  <li className="step__item">
                    <div className="step__avatar">
                      <img src={step_3} alt="" />
                    </div>
                    <h3 className="step__note">
                      3. Earn TIIM everyday
                      <span>
                        Many activities with excited prizes are waiting you
                        everyday
                      </span>
                    </h3>
                  </li>
                </ul>
                {!authen.isLogin && (
                  <div className="button-wrap">
                    <button
                      onClick={() => setOpenLogin(true)}
                      className="button button--big button--secondary"
                    >
                      Sign up now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="services">
            <div className="container">
              <h2 className="services__title title">Utilities and services</h2>
              <div className="services__tab">
                <div className="tab-header">
                  <div
                    className={`tab-header__item ${
                      tabActive === 1 ? "active" : ""
                    }`}
                    onClick={() => setTabActive(1)}
                  >
                    Hotel Booking
                  </div>
                  <div
                    className={`tab-header__item ${
                      tabActive === 2 ? "active" : ""
                    }`}
                    onClick={() => setTabActive(2)}
                  >
                    Travel
                  </div>
                  <div
                    className={`tab-header__item ${
                      tabActive === 3 ? "active" : ""
                    }`}
                    onClick={() => setTabActive(3)}
                  >
                    Online Shopping
                  </div>
                </div>
                <div className="tab-content">{handleTab(tabActive)}</div>
              </div>
            </div>
          </section>
          <section className="slide">
            <div className="container">
              <div className="slide__inner">
                <h2 className="title">Hot deals, join now</h2>
                <Slider {...slideSettings}>
                  {sliderItem.map((item) => (
                    <div className="slide__item" key={item.id}>
                      <div className="slide__avatar">
                        <img src={tiim_img} alt="" />
                      </div>
                      <h3 className="slide__note">{item.note}</h3>
                    </div>
                  ))}
                </Slider>
                <div className="button-wrap">
                  <a href="/download">
                    <button className="button button--big button--secondary">
                      Sign up for free
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
