let Env = {
  NODE_ENV: undefined,
  BASE_URL: undefined,
  TRIIP_EXPERIENCE_URL: undefined,
  TRIIP_STAY_URL: undefined,
  TRIIP_SHOPPING_URL: undefined,
  TRIIP_SHARE_URL: undefined,
  SCAN_CHAIN_NETWORK: undefined,
  RECAPTCHA_SITE_KEY: undefined,
};

const processEnv = {
  NODE_ENV: process.env.NODE_ENV,
  BASE_URL: process.env.BASE_URL,
  TRIIP_EXPERIENCE_URL: process.env.TRIIP_EXPERIENCE_URL,
  TRIIP_STAY_URL: process.env.TRIIP_STAY_URL,
  TRIIP_SHOPPING_URL: process.env.TRIIP_SHOPPING_URL,
  TRIIP_SHARE_URL: process.env.TRIIP_SHARE_URL,
  SCAN_CHAIN_NETWORK: process.env.SCAN_CHAIN_NETWORK,
  RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
}

Env = window._env_ || processEnv;
export default Env;
