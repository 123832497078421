import { LOGOUT, LOGIN_SUCCESS, GETINFOR_SUCCESS } from "../actions/authen";
import { createAuthCookie } from '../../utils/authCookie';
const defaultState = {
  userInfor: {},
  isLogin: false,
  token: "",
  error: null,
};

const authen = (state = defaultState, action) => {
  switch (action.type) {
    case "persist/REHYDRATE":
      if (action.payload && action.payload.main) {
        return {
          ...state,
          ...action.payload.authen,
        };
      } else {
        return { ...state };
      }
    case LOGIN_SUCCESS:
      createAuthCookie("triip_auth_token_", action.payload.auth_token, 30);
      createAuthCookie("triip_user_code_", action.payload.data.code, 30);
      return {
        ...state,
        isLogin: true,
        userInfor: action.payload.data,
        token: action.payload.auth_token,
      };
    case GETINFOR_SUCCESS:
      return {
        ...state,
        isLogin: true,
        userInfor: action.payload.data,
        token: action.payload.auth_token,
      };
    case LOGOUT: {
      createAuthCookie("triip_auth_token_", "", 0);
      createAuthCookie("triip_user_code_", "", 0);
      return {
        ...state,
        isLogin: false,
        userInfor: {},
        token: "",
      };
    }
    default:
      return state;
  }
};

export default authen;
