import React from "react";
import "./index.css";
import Home from "./home";
import { Provider } from "react-redux";
import { store } from "./redux/stores";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import "antd/dist/antd.css";
const HomePage = () => {
  return (
    <Provider store={store}>
      <Home />
    </Provider>
  );
};
export default HomePage;
