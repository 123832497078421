/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { Modal, DropdownButton, Dropdown } from "react-bootstrap";
import { Badge } from "antd";
import {
  UserOutlined,
  InboxOutlined,
  WalletOutlined,
  SettingOutlined,
  CheckCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import styles from "../../styles/Pages/heading.module.scss";
import {
  getTIIMPrice,
  getUserWallet,
  checkProfile
} from "../../utils/services";
import LoginRegister from "../LoginRegister";
import logo from "../../assets/images/logo.png";
import bicycle from "../../assets/images/bicycle.svg";
import Env from '../../utils/environments';
import { getAuthCookie } from '../../utils/authCookie'
import { logout } from "../../redux/actions/authen";

const index = () => {
  const BASE_TOMOCHAIN_URL = `${Env.SCAN_CHAIN_NETWORK}/address/`;
  const dispatch = useDispatch();
  const [type, setType] = useState(1);
  const [email, setEmail] = useState("");
  const authen = useSelector((state) => state.authen);
  const [price, setPrice] = useState(0);
  const [wallet, setWallet] = useState(null);
  const [show, setShow] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const t = (value) => value;

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (authen.isLogin) {
      getWallet();
    }
  }, [authen.isLogin]);

  const checkLogin = async () => {
    let auth_token = getAuthCookie("triip_auth_token_");
    let user_code = getAuthCookie("triip_user_code_");
    if (auth_token && user_code) {
      try {
        const result = await checkProfile(auth_token, user_code);
        if (result) {
          dispatch({
            type: "GETINFOR_SUCCESS",
            payload: {
              data: result,
              auth_token,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      dispatch(logout(onLogoutSuccess, onLogoutError));
    }
  };

  const getPrice = async (amount) => {
    try {
      const headersAuthen = authen.token;
      const userCode = authen.userInfor.code;
      const params = {
        to: "USD",
        amount,
      };
      let result = await getTIIMPrice(headersAuthen, userCode, params);
      setPrice(result.price_amount_text);
    } catch (e) {}
  };

  const getWallet = async () => {
    try {
      const headersAuthen = authen.token;
      const userCode = authen.userInfor.code;
      let result = await getUserWallet(headersAuthen, userCode);
      let item = result.find((item) => item.currency === "TIIM");
      if (item) {
        getPrice(item.balance);
        setWallet(item);
      }
    } catch (e) {}
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout(onLogoutSuccess, onLogoutError));
  };

  const onLogoutSuccess = (data) => {
    window.location.reload()
  };

  const onLogoutError = (data) => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  const handleRedirect = (url) => {
    window.location.href = url;
  }

  return (
    <header className="header" className={styles.header_container}>
      <div className="container_heading">
        <div className="header__inner">
          <div className="header__menu">
            <div className="header__logo">
              <div
                className={`hamburger ${toggleMenu ? "active" : ""}`}
                onClick={() => setToggleMenu(!toggleMenu)}
              >
                <span />
              </div>
              <h1 className="logo">
                <a href={`${Env.BASE_URL}`}>
                  <img src={logo} alt="" />
                </a>
              </h1>
              <div
                className={styles.cart_container__mobile}
              >
              </div>
            </div>
            <ul className={`menu ${toggleMenu ? "active" : ""}`}>
              <li className="menu__item">
                <a href={Env.TRIIP_STAY_URL} className="menu__link">
                  {t("stay")}
                </a>
              </li>
              <li className="menu__item">
                <a href={Env.TRIIP_EXPERIENCE_URL} className="menu__link">
                  {t("experience")}
                </a>
              </li>
              <li className="menu__item">
                <a href={Env.TRIIP_SHARE_URL} className="menu__link">
                  {t("share")}
                </a>
              </li>
              <li className="menu__item">
                <a href={Env.TRIIP_SHOPPING_URL} className="menu__link">
                  {t("shop")}
                </a>
              </li>
              {authen.isLogin ? (
                <>
                  <li className="menu__item_mobile menu__item_mobile__line"></li>
                  <li className="menu__item_mobile">
                    <a
                      onClick={() => handleRedirect(`${BASE_TOMOCHAIN_URL}${authen.userInfor.wallet_address}`)}
                      className="menu__link"
                    >
                      You have: {wallet ? wallet.balance : 0} TIIM (
                      {price ? price : 0})
                    </a>
                  </li>
                  <li className="menu__item_mobile">
                    <a
                      onClick={() => handleRedirect(`${Env.BASE_URL}/users/${authen.userInfor.id}`)}
                      className="menu__link"
                    >
                      <UserOutlined className="mr-2" />
                      {authen.userInfor.name}
                    </a>
                  </li>
                  <li className="menu__item_mobile">
                    <a
                      onClick={() => handleRedirect(`${Env.BASE_URL}/me/inbox`)}
                      className="menu__link"
                    >
                      <InboxOutlined className="mr-2" />
                      {t("Inbox")}
                    </a>
                  </li>
                  {authen.userInfor.user_type == 'tour_operator' ? (
                    <li className="menu__item_mobile">
                      <a
                        onClick={() => handleRedirect(`${Env.BASE_URL}/me/master_tours/home`)}
                        className="menu__link"
                      >
                        <img
                          widt="14"
                          height="14"
                          className="mr-2"
                          src={bicycle}
                        />
                        {t("Tours")}
                      </a>
                    </li>
                  ) : (
                    <li className="menu__item_mobile">
                      <a
                        onClick={() => handleRedirect(`${Env.BASE_URL}/me/trips`)}
                        className="menu__link"
                      >
                        <img
                          widt="14"
                          height="14"
                          className="mr-2"
                          src={bicycle}
                        />
                        {t("Trips")}
                      </a>
                    </li>
                  )}
                  <li className="menu__item_mobile">
                    <a
                      onClick={() => handleRedirect(`${Env.BASE_URL}/wallet`)}
                      className="menu__link"
                    >
                      <WalletOutlined className="mr-2" />
                      {t("Wallet")}
                    </a>
                  </li>
                  <li className="menu__item_mobile">
                    <a onClick={() => handleRedirect(`${Env.BASE_URL}/me`)} className="menu__link">
                      <SettingOutlined className="mr-2" />
                      {t("Settings")}
                    </a>
                  </li>
                  <li
                    className="menu__item_mobile"
                    onClick={handleLogout}
                  >
                    <LogoutOutlined className="mr-2" />
                    {t("Logout")}
                  </li>
                </>
              ) : (
                <>
                  <li className="menu__item_mobile menu__item_mobile__line"></li>
                  <li className="menu__item_mobile">
                    <a
                      className="menu__link"
                      target="_blank"
                      href="https://globaltix.com/sign-up-merchant-account/?affiliate_id=tou-ss"
                      rel="noreferrer"
                    >
                      Become a partner
                    </a>
                  </li>
                  <li className="menu__item_mobile menu__item_mobile__button">
                    <a data-method="get" data-remote="true" href="/users/sign_in">Login</a>
                  </li>
                </>
              )}
            </ul>
          </div>
          {authen.isLogin ? (
            <div className="header__option">
              <a
                target="_blank"
                className="mr-2 link_coin"
                onClick={() => handleRedirect(`${BASE_TOMOCHAIN_URL}${authen.userInfor.wallet_address}`)}
                rel="noreferrer"
              >
                You have: {wallet ? wallet.balance : 0} TIIM (
                {price ? price : 0})
              </a>
              <DropdownButton
                id="dropdown-basic-button"
                title={<span>{authen.userInfor.name}</span>}
              >
                <Dropdown.Item
                  onClick={() => handleRedirect(`${Env.BASE_URL}/users/${authen.userInfor.id}`)}
                >
                  <UserOutlined className="mr-2" />
                  {authen.userInfor.name}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleRedirect(`${Env.BASE_URL}/me/inbox`)}>
                  <InboxOutlined className="mr-2" />
                  {t("Inbox")}
                </Dropdown.Item>
                {authen.userInfor.user_type == 'tour_operator' ? (
                  <Dropdown.Item onClick={() => handleRedirect(`${Env.BASE_URL}/me/master_tours/home`)}>
                    <img widt="14" height="14" className="mr-2" src={bicycle} />
                    {t("Tours")}
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={() => handleRedirect(`${Env.BASE_URL}/me/trips`)}>
                    <img widt="14" height="14" className="mr-2" src={bicycle} />
                    {t("Trips")}
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => handleRedirect(`${Env.BASE_URL}/wallet`)}>
                  <WalletOutlined className="mr-2" />
                  {t("Wallet")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleRedirect(`${Env.BASE_URL}/me`)}>
                  <SettingOutlined className="mr-2" />
                  {t("Settings")}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={handleLogout}
                >
                  <LogoutOutlined className="mr-2" />
                  {t("Logout")}
                </Dropdown.Item>
              </DropdownButton>
            </div>
          ) : (
            <div className="header__option">
              <a
                target="_blank"
                href="https://globaltix.com/sign-up-merchant-account/?affiliate_id=tou-ss"
                rel="noreferrer"
              >
                Become a partner
              </a>
              <a className="header__button button" data-method="get" data-remote="true" href="/users/sign_in">Login</a>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default index;
