import { post, del } from "../../utils/api";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const GETINFOR_SUCCESS = "GETINFOR_SUCCESS";
export const LOGOUT = "LOGOUT";

export const loginSuccess = (data) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: data,
  });
};

export const registerSuccess = (data) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: data,
  });
};

export const submitLogin = (data, onSuccess, onError) => (dispatch) => {
  const url = `api/v1/login_with_email`;
  post({ url, data })
    .then(({ data }) => {
      dispatch(loginSuccess(data));
      onSuccess();
    })
    .catch((error) => {
      onError();
    });
};

export const submitRegister = (data, onSuccess, onError) => (dispatch) => {
  const url = `api/v1/users`;
  post({ url, data })
    .then(({ data }) => {
      onSuccess();
    })
    .catch((error) => {
      onError(error);
    });
};

export const logout = (onSuccess, onError) => (dispatch) => {
  const url = '/users/sign_out';
  const headersAuthen = document.querySelector('meta[name="csrf-token"]').content
  del({ url, headersAuthen })
    .then(({ data }) => {
      dispatch({
        type: LOGOUT,
      });
      onSuccess(data);
    })
    .catch((error) => {
      onError(error);
    });
}
