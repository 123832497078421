import * as t from "../actions/types";

const main = (
  state = {
    name: "guest",
    loading: true,
  },
  action
) => {
  switch (action.type) {
    case "persist/REHYDRATE":
      if (action.payload && action.payload.main) {
        return {
          ...state,
          ...action.payload.main,
          loading: false,
        };
      } else {
        return { ...state };
      }
    case t.SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    default:
      return { ...state };
  }
};

export default main;
