import { combineReducers } from "redux";
import main from "./main";
import authen from "./authen";

const rootReducer = combineReducers({
  main,
  authen,
});

export default rootReducer;
