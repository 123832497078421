import React, { useState, useRef } from "react";
import Reaptcha from "reaptcha";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Env from "../../utils/environments";
import { submitRegister } from "../../redux/actions/authen";
const Register = ({ setShow, onClose, setEmail }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const captcharef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [inforRegister, setInforRegister] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    referral_code: "",
    g_recaptcha_response: "",
  });
  const [errorForm, seterrorForm] = useState({
    first_name: {
      required: false,
    },
    last_name: {
      required: false,
    },
    email: {
      required: false,
      format: false,
    },
    password: {
      required: false,
    },
    g_recaptcha_response: {
      required: false,
    },
  });

  const t = (value) => value;

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const submit = (e) => {
    e.preventDefault();
    if (validateField()) {
      return false;
    }

    dispatch(submitRegister(inforRegister, onSuccess, onError));
  };

  const onError = (error) => {
    if (captcharef) {
      captcharef.current.reset();
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      setErrorMessage(error.response.data.message);
    }
  };

  const onSuccess = () => {
    setShow(true);
    onClose();
    setEmail(inforRegister.email);
    if (captcharef) {
      captcharef.current.reset();
    }
  };

  const validateField = () => {
    const { first_name, last_name, password, email, g_recaptcha_response } =
      inforRegister;
    const validateForm = {
      first_name: {
        required: first_name === "",
      },
      last_name: {
        required: last_name === "",
      },
      email: {
        required: email === "",
        format: email !== "" && !validateEmail(email),
      },
      password: {
        required: password === "",
      },
      g_recaptcha_response: {
        required: g_recaptcha_response === "",
      },
    };

    const check = Object.values(validateForm).filter((item) => {
      if (item.required || item.format) {
        return true;
      }
      return false;
    });
    const newvalidInfor = { ...errorForm, ...validateForm };
    seterrorForm(newvalidInfor);
    if (check.length !== 0) {
      return true;
    }
    return false;
  };

  const handleChangeInfor = (value, name) => {
    const newInfor = Object.assign({}, inforRegister);
    const newValidateForm = Object.assign({}, errorForm);
    newInfor[name] = value;
    newValidateForm[name].required = false;
    setInforRegister(newInfor);
    seterrorForm(newValidateForm);
    setErrorMessage("");
    ref.current = newInfor;
  };

  const handleVerify = (token) => {
    const newInfor = Object.assign({}, ref.current);
    newInfor["g_recaptcha_response"] = token;
    const newValidateForm = Object.assign({}, errorForm);
    newValidateForm["g_recaptcha_response"].required = false;
    seterrorForm(newValidateForm);
    setErrorMessage("");
    setInforRegister(newInfor);
  };

  return (
    <div className="login">
      <form onSubmit={submit} className="form__box">
        <div className="field-row">
          <div className="field">
            <label htmlFor="first-name" className="field__label">
              First name
            </label>
            <input
              type="text"
              className="field__input"
              id="first-name"
              onChange={(e) => handleChangeInfor(e.target.value, "first_name")}
            />
            {errorForm.first_name.required && (
              <span className="text-red-400">Please fill out this field</span>
            )}
          </div>
          <div className="field">
            <label htmlFor="last-name" className="field__label">
              Last name
            </label>
            <input
              type="text"
              className="field__input"
              id="last-name"
              onChange={(e) => handleChangeInfor(e.target.value, "last_name")}
            />
            {errorForm.last_name.required && (
              <span className="text-red-400">Please fill out this field</span>
            )}
          </div>
        </div>
        <div className="field-row">
          <div className="field">
            <label htmlFor="email" className="field__label">
              Email
            </label>
            <input
              type="email"
              className="field__input"
              id="email"
              placeholder="your email is also the ownership ID"
              onChange={(e) => handleChangeInfor(e.target.value, "email")}
            />
            {errorForm.email.required && (
              <span className="text-red-400">Please fill out this field</span>
            )}
            {errorForm.email.format && (
              <span className="text-red-400">This email not valid</span>
            )}
          </div>
        </div>
        <div className="field-row">
          <div className="field">
            <label htmlFor="password" className="field__label">
              Password
            </label>
            <input
              type="password"
              className="field__input"
              id="password"
              onChange={(e) => handleChangeInfor(e.target.value, "password")}
            />
            {errorForm.password.required && (
              <span className="text-red-400">Please fill out this field</span>
            )}
          </div>
        </div>
        <div className="field-row mt-3 flex items-stretch ">
          <div className="pr-2">
            <Reaptcha
              ref={captcharef}
              sitekey={Env.RECAPTCHA_SITE_KEY}
              onVerify={handleVerify}
            />
            {errorForm.g_recaptcha_response.required && (
              <span className="text-red-400">
                Please prove that you're a traveler, not a bot. Otherwise, we
                can not let you through.
              </span>
            )}
            {errorMessage && (
              <span className="text-red-400">{errorMessage}</span>
            )}
          </div>
        </div>
        <button
          type="submit"
          onClick={submit}
          className="button w-full mt-4 button--primary"
        >
          Join Now
        </button>
      </form>
    </div>
  );
};

export default Register;
